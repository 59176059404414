.menu-wrapper {
    .category-wrapper {
        padding: 0;
        width: 95%;
        border-radius: 20px !important;
        margin-left: auto;
        margin-right: auto;
        display: block;
        .p-panel-titlebar{
            // background: transparent !important;
        }
        .p-panel.customer {
            border-radius: 10px 10px 0 0 !important;
            .p-panel-content {
                padding: 0.625em 0.5em;
                border-radius: 20px !important;
                .item-price {
                    .title-line {
                        width: 100%;
                        min-height: 35px;
                        position: relative;
                        display: inline-flex;
                        .title-standalone {
                            padding-right: unset;
                            h2 {
                                margin: 0;
                            }
                            .price-box {
                                font-weight: 700;
                            }
                        }
                        .title {
                            // padding-right: 40px;
                            h2 {
                                margin: 0;
                            }
                            .price-box {
                                font-weight: 700;
                            }
                        }
                        .price {
                            position: absolute;
                            right: 0;
                            .p-button {
                                margin-right: 0;
                            }
                        }
                    }
                    .variant.title {
                        width: 100%;
                        position: relative;
                        display: block;
                        border-left: 2px solid #d8d9db;
                        padding-left: 10px;
                        // h2{
                        //     border-left: 2px solid #d8d9db;
                        //     padding-left: 10px;
                        //     width: 75%;
                        // }
                        .price-box {
                            // position: absolute;
                            // right: 0;
                            font-weight: 700;
                            margin: 5px 0;
                        }
                    }
                }
            }
        }
    }
    .c-menu-cart-discount {
        font-size: 17px;
        font-weight: bold;
        padding: 0px;
        margin: 5px;
        color: #322EE6;
    }
    .topbar-header {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    }
}
.variant-title{
    border-left: 2px solid #d8d9db;
    padding-left: 10px;
    margin-top: 7px;
    font-weight: bold; 
    color: #0a0a0a;
    //     width: 100%;
    //     position: relative;
    //     display: inline-flex;
}
.price-box-variant {
    position: absolute;
    right: 0;
    font-weight: normal; 
}
.price-box {
    font-size: 16px !important;
    @media(max-width: 1024px){
        font-size: 16px !important;
    }
    @media(max-width: 768px){
        font-size: 14px !important;
    }
    @media(max-width: 576px){
        font-size: 13px !important;
    }
    @media(max-width: 360px){
        font-size: 13px !important;
    }
}
.food-title-frontpage{
    font-size: 17px !important;
    @media(max-width: 1024px){
        font-size: 18px !important;
    }
    @media(max-width: 768px){
        font-size: 14px !important;
    }
}
.c-menu-wrapper{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 90%;
    margin-top: -1%;
}
.title{
    margin-bottom: 0px;
    font-weight: lighter;
}
.c-menu-item{
    border-radius: 20px !important;
}
.c-menu-item-active{
    border: none !important;
    background-color:#BEEEFF !important;
}
.searchbox{
    background: white !important;
    margin-left: auto;
    margin-right: auto;
    // display: block;
    margin-top: 5px;
    border: 1px solid #7a7a7a !important;
    padding: 10px !important;
    border-radius: 5px !important;
    width: 90%;
    // margin-right: 20px;
}
.searchbox-margin{
    margin-top: 65px;
}
.order-status-warn{
    margin-top: 3%;
}
.search-button{
    color:white !important;
    margin-left: auto;
    margin-right: auto;
    // display: block;
    margin-top: -2px;
    padding: 10px !important;
    border-radius: 0px 5px 5px 0px!important;
    width: 10%;
    height: 42px!important;
}
.categories-dropdown{
    background: white !important;
    margin-left: auto;
    margin-right: auto;
    border-color:transparent !important;
    width: 80%;
}
.p-accordion-header-customer{
    border-radius:25px !important;
}
.menu-dish-image .dish-image-square{
    width: 100%;
    position: relative;
    margin-top: 7px;
    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
        // @media(max-width:576px) {
        //     padding-bottom: 50%;
        // }
    }

    img {
        position: absolute;
        width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}
.ap-dish-image .dish-image-square{
    width: 100%;
    position: relative;
    margin-top: 7px;
    &:after {
        content: "";
        display: block;
        padding-bottom: 65%;
        // @media(max-width:576px) {
        //     padding-bottom: 50%;
        // }
    }

    img {
        position: absolute;
        width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}
.btn-add-order{
    border-radius: 60px !important;
    color: white !important;
    background: linear-gradient(to top right,#1ca7ec, #7bd5f5 );
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    cursor: pointer;
    font-size: 18px!important;
    margin-top: 10px;
    float: right;
    margin-right: unset;
}

.btn-view-ap{
    border-radius: 60px !important;
    color: white !important;
    background: linear-gradient(to top right,#1ca7ec, #7bd5f5 );
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    cursor: pointer;
    font-size: 18px!important;
    margin-top: 10px;
    margin-right: unset;
}
.space{
    padding-bottom: 100px !important;
}
@media (max-width: 768px) {
    .c-menu-wrapper{
        margin-top: -1%;
        margin-bottom: -1%;
        width: 90%;
    }
    .order-status-warn{
        margin-top: 65px;
    }
    .searchbox-margin{
        margin-top: 65px;
    }
    .searchbox{
        width: 80%;
    }
}
@media (max-width: 576px) {
    .c-menu-wrapper{
        margin-top: -3%;
        margin-bottom: -3%;
        width: 95%;
    }
    .order-status-warn{
        margin-top: 65px;
    }
    .searchbox-margin{
        margin-top: 65px;
    }
    .searchbox{
        width: 80%;
    }
}

.filter-menu-customer{
    text-align: center;
    margin-bottom: 5px;
}