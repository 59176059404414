$black: #333333;
$blue: #686de0;
$gray: #d3d3d3;
$green: #6ab04c;
$white: #ffffff;
$red: #c80707;

.pin{
  margin:auto;
  margin-top: 10% !important;
  padding:20px !important;
  display: block;
  background: white;
  border-radius: 20px;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  @media (max-width: 768px) {
    width: 70%;
  }
  @media (max-width: 576px) {
    width: 90%;
  }
}
code {
  padding: 5px 10px;
  display: inline-block;
}

.container-a {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 10px;
  justify-content: center;
  margin: 20px 0;
}

.field-a {
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
  outline: none;
  border-radius: 5px;
  border: 1px solid $gray;
  transition-property: color, border, box-shadow, transform;
	transition-duration: 250ms;
	-moz-appearance: textfield;

  &:focus {
    outline: none;
    box-shadow: 0 0 7px rgba($blue, 0.5);
    border: 1px solid $blue;
    transform: scale(1.05);
  }
}

.field-a::-webkit-outer-spin-button,
.field-a::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.field-a-complete {
  border: 1px solid $green;
  color: $green;

  &[disabled] {
    background: rgba($green, 0.1);
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.field-a-error {
  border: 1px solid $red;
  color: $red;

  &[disabled] {
    background: rgba($red, 0.1);
    opacity: 0.5;
    cursor: not-allowed;
  }
}