.p-split {
    margin-left: auto;
    margin-right: auto;
}

.p-split-slider-first {
    span.p-slider-handle.p-slider-handle-start {
        display: none;
    }
}

.p-split-slider-last {
    span.p-slider-handle.p-slider-handle-end {
        display: none;
    }
}

.btn-make-payment{
    background: linear-gradient(to top right, #1CBB51, #4ADE8D) !important;
    color: white !important;
    border-radius: 20px !important;
    box-shadow: none !important;
  }
.btn-cancel{
    background:white !important;
    border: 1.5px #4ADE8D solid !important;
    color: #4ADE8D !important;
    font-weight: bold !important;
    border-radius: 20px !important;
    box-shadow: none !important;
  }

.num-person{
    overflow: hidden;
    width:60px;
    font-size:20px;
    margin-left:5px;
    margin-bottom:20px;
}

.num-percentage{
    margin-bottom: 15px !important;
}