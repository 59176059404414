/* Place your extensions here to make migration to a new release easier. */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
.exception-body {
    height: calc(100vh - 130px);
    min-height: 300px;
    font-size: 16px;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
}

.exception-container {
    height: 100%;
    min-height: 240px;
    padding-left: 100px;
    padding-top: calc(50vh - 140px);
}

.exception-title {
    font-size: 80px;
    color: #424242;
    font-weight: normal;
    margin: 0;
}

.display-flex {
    display: flex;
}

/* Custom css */
body{
    font-family: "Open Sans", sans-serif;
}
body.p-overflow-hidden{
    position: inherit;
}
/*---Space define---*/
.p-space3{ height: 3px; width: 100%; float: left; }
.p-space5{ height: 5px; width: 100%; float: left; }
.p-space10{ height: 10px; width: 100%; float: left; }
.p-space15{ height: 15px; width: 100%; float: left; }
.p-space20{ height: 20px; width: 100%; float: left; }
.p-space30{ height: 30px; width: 100%; float: left; }
.p-space40{ height: 40px; width: 100%; float: left; }
.p-space50{ height: 50px; width: 100%; float: left; }
.p-space60{ height: 60px; width: 100%; float: left; }
.p-space70{ height: 70px; width: 100%; float: left; }
.p-space80{ height: 80px; width: 100%; float: left; }
.p-space90{ height: 90px; width: 100%; float: left; }
.p-space100{ height: 100px; width: 100%; float: left; }

/*---Margin define---*/
.p-margin-all-10 {margin: 10px!important;}
.p-margin-all-15 {margin: 15px!important;}
.p-margin-all-20 {margin: 20px!important;}
.p-margin-all-30 {margin: 30px!important;}

.p-margin-5-0 {margin: 5px 0px!important;}
.p-margin-10-0 {margin: 10px 0px!important;}
.p-margin-15-0 {margin: 15px 0px!important;}
.p-margin-20-0 {margin: 20px 0px!important;}
.p-margin-30-0 {margin: 30px 0px!important;}

.p-margin-0-5 {margin: 0px 5px!important;}
.p-margin-0-10 {margin: 0px 10px!important;}
.p-margin-0-15 {margin: 0px 15px!important;}
.p-margin-0-20 {margin: 0px 20px!important;}
.p-margin-0-30 {margin: 0px 30px!important;}

.p-margin-top-0 {margin-top: 0px!important;}
.p-margin-top-5 {margin-top: 5px!important;}
.p-margin-top-10 {margin-top: 10px!important;}
.p-margin-top-15 {margin-top: 15px!important;}
.p-margin-top-20 {margin-top: 20px!important;}
.p-margin-top-30 {margin-top: 30px!important;}

.p-margin-right-0 {margin-right: 0px!important;}
.p-margin-right-5 {margin-right: 5px!important;}
.p-margin-right-10 {margin-right: 10px!important;}
.p-margin-right-15 {margin-right: 15px!important;}
.p-margin-right-20 {margin-right: 20px!important;}
.p-margin-right-30 {margin-right: 30px!important;}

.p-margin-bottom-0 {margin-bottom: 0px!important;}
.p-margin-bottom-5 {margin-bottom: 5px!important;}
.p-margin-bottom-10 {margin-bottom: 10px!important;}
.p-margin-bottom-15 {margin-bottom: 15px!important;}
.p-margin-bottom-20 {margin-bottom: 20px!important;}
.p-margin-bottom-30 {margin-bottom: 30px!important;}

.p-margin-left-0 {margin-left: 0px!important;}
.p-margin-left-5 {margin-left: 5px!important;}
.p-margin-left-10 {margin-left: 10px!important;}
.p-margin-left-15 {margin-left: 15px!important;}
.p-margin-left-20 {margin-left: 20px!important}
.p-margin-left-25 {margin-left: 25px!important;}
.p-margin-left-30 {margin-left: 30px!important;}
.p-margin-left-35 {margin-left: 35px!important;}
.p-margin-left-200 {margin-left: 200px!important;}
.p-margin-left-100 {margin-left: 100px!important;}

.p-margin-auto {margin: auto!important;}
.p-margin-none {margin: 0px!important;}

/*---Padding define---*/
.p-padding-all-5 {padding: 5px!important;}
.p-padding-all-10 {padding: 10px!important;}
.p-padding-all-15 {padding: 15px!important;}
.p-padding-all-20 {padding: 20px!important;}
.p-padding-all-30 {padding: 30px!important;}

.p-padding-5-0 {padding: 5px 0px!important;}
.p-padding-10-0 {padding: 10px 0px!important;}
.p-padding-15-0 {padding: 15px 0px!important;}
.p-padding-20-0 {padding: 20px 0px!important;}
.p-padding-30-0 {padding: 30px 0px!important;}

.p-padding-0-5 {padding: 0px 5px!important;}
.p-padding-0-10 {padding: 0px 10px!important;}
.p-padding-0-15 {padding: 0px 15px!important;}
.p-padding-0-20 {padding: 0px 20px!important;}
.p-padding-0-30 {padding: 0px 30px!important;}

.p-padding-top-0 {padding-top: 0px!important;}
.p-padding-top-5 {padding-top: 5px!important;}
.p-padding-top-10 {padding-top: 10px!important;}
.p-padding-top-15 {padding-top: 15px!important;}
.p-padding-top-20 {padding-top: 20px!important;}
.p-padding-top-30 {padding-top: 30px!important;}

.p-padding-right-0 {padding-right: 0px!important;}
.p-padding-right-10 {padding-right: 10px!important;}
.p-padding-right-15 {padding-right: 15px!important;}
.p-padding-right-20 {padding-right: 20px!important;}
.p-padding-right-30 {padding-right: 30px!important;}

.p-padding-bottom-0 {padding-bottom: 0px!important;}
.p-padding-bottom-5 {padding-bottom: 5px!important;}
.p-padding-bottom-10 {padding-bottom: 10px!important;}
.p-padding-bottom-15 {padding-bottom: 15px!important;}
.p-padding-bottom-20 {padding-bottom: 20px!important;}
.p-padding-bottom-30 {padding-bottom: 30px!important;}

.p-padding-left-0 {padding-left: 0px!important;}
.p-padding-left-10 {padding-left: 10px!important;}
.p-padding-left-15 {padding-left: 15px!important;}
.p-padding-left-20 {padding-left: 20px!important;}
.p-padding-left-30 {padding-left: 30px!important;}

.p-padding-auto {padding: auto!important;}
.p-padding-none {padding: 0px!important;}

.p-hide {display: none!important;}

.p-label{
    display: block;
    font-weight: 600;
    opacity: 0.7;
    /* margin-bottom: 10px; */
}

.p-hz-label{
    display: block;
    font-weight: 600;
    opacity: 0.7;
    padding-top: 2px;
    float: right;
}

.p-c{
    text-align: center;
}
.p-r{
    text-align: right;
}
.p-l{
    text-align: left;
}

.p-float-left{
    float: left;
}
.p-float-right{
    float: right;
}

.p-fieldset-line{
    width: 105%;
    margin: 0 -0.5em;
    border-bottom: 1px solid #d8d8d8;
    height: 1px;
}

.p-hr{
    border-bottom: 1.5px solid #d8d8d8;
    display: block;
    font-size: 15px;
    font-weight: bold;
    /* opacity: 0.7; */
    padding: 5px 0;
}

.p-w-bold{
    font-weight: bold!important;
}
.p-w-normal{
    font-weight: normal!important;
}

.p-size-10{ font-size: 10px!important; }
.p-size-11{ font-size: 11px!important; }
.p-size-12{ font-size: 12px!important; }
.p-size-13{ font-size: 13px!important; }
.p-size-14{ font-size: 14px!important; }
.p-size-16{ font-size: 16px!important; }
.p-size-18{ font-size: 18px!important; }
.p-size-20{ font-size: 20px!important; }
.p-size-24{ font-size: 24px!important; }
.p-size-30{ font-size: 30px!important; }
.p-size-36{ font-size: 36px!important; }
.p-size-48{ font-size: 48px!important; }
.p-size-60{ font-size: 60px!important; }
.p-size-72{ font-size: 72px!important; }
.p-size-90{ font-size: 90px!important; }

/*-- Override CSS*/
.p-grid.form-group > div {
    padding: 0.6em;
}

body .p-inputtext{
    padding-bottom: 3px;
}

body .p-dropdown .p-dropdown-label {
    padding-bottom: 0px;
}

.p-dropdown .p-dropdown-label {
    white-space: pre-line;
}

.p-inputgroup .p-inputgroup-addon {
    min-width: fit-content;
}

.layout-wrapper .layout-sidebar .layout-menu li > a > .menuitem-text {
    max-width: 150px;
}

.p-form-error{
    color: #cc0000
}

.p-form-warning{
    color: #ffba01;
}

.p-chips-token{
    padding: 3px 5px!important;
    margin-bottom: 5px!important;
    margin-right: 10px!important;
}

.p-chips > ul.p-inputtext .p-chips-input-token{
    margin-bottom: 5px!important;
    padding: 3px 5px 5px 5px!important;
    border: 1px solid #ddd;
    border-radius: 3px;
}

.p-nowrap {
    white-space: nowrap;
}
.p-lowercase {
    text-transform: lowercase;
}
.p-uppercase {
    text-transform: uppercase;
}
.p-capitalize {
    text-transform: capitalize;
}

.p-datatable .p-datatable-tbody > tr > td{
    overflow: hidden;
    text-overflow: ellipsis;
}

.p-datatable .p-datatable-tbody > tr > td.tc-actions{
    overflow: visible;
}

.btn-sm {
    /* padding: 5px 10px!important; */
    font-size: 12px!important;
    line-height: 1.5!important;
}

.btn-xs {
    /* padding: 1px 5px!important; */
    font-size: 10px!important;
    line-height: 1.5!important;
}

.btn-s {
    /* padding: 1px 5px!important; */
    font-size: 10px!important;
    line-height: 1.5!important;
    /* width: 135px!important; */
    color:white!important;
    font-family: monospace!important;
}

.btn-s:hover {
    background-color: grey!important;
}

body .p-datatable .p-datatable-header h3.fle-datatable-header-title {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0.3em;
    color: #ffffff;
    font-weight: bold;
}

body .p-dialog .p-dialog-titlebar {
    border-bottom: 1px solid #dbdbdb;
}

body .p-dialog .p-dialog-content {
    overflow: auto;
    max-height: 500px;
    min-height: 250px;
}

body .p-growl{
    z-index: 9999999!important;
}

.p-datatable-length-selector .p-dropdown-trigger {
    color: #5fa716!important;
}

.p-datatable-length-selector .p-dropdown-items-wrapper {
    max-height: 1000px!important;
}

/* .p-dropdown-items-wrapper {
    width: 400px;
} */

a.nav-link{
    text-decoration: underline;
}
a.nav-link:hover{
    color: #4b5ef1;
}

.p-priority{
    box-shadow: 0 0 0 1pt #ffffff;
}
.p-priority-1{
    border-left: 5px solid #37b96d;
}
.p-priority-2{
    border-left: 5px solid #2e87e0;
}
.p-priority-3{
    border-left: 5px solid #f7f134;
}
.p-priority-4{
    border-left: 5px solid #fca121;
}
.p-priority-5{
    border-left: 5px solid #e15139;
}

body .p-datatable-scp .p-datatable-footer{
    background-color: transparent;
    border-color: #cacaca;
}
body .p-datatable-scp .p-datatable-tbody > tr:nth-child(even){
    background-color: transparent;
}

button.p-button.p-component.p-button-warning.p-button-icon-only.cancel-button{
    position: absolute;
    z-index: 1;
    right: -13px;
    color: white;
    background-color: #ff8000;
    margin-top: -20px;
    margin-right: 12px;
    font-size: 9px;
    border: 1px solid #ff8000;
    box-shadow: none;
    top: 19px;
    border-radius: 0 0px 0px 10px!important;
}

ul.p-list-group{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
ul.p-list-group li{
    margin: 5px 0px 10px 0px;
    border-bottom: 1px solid #d8d8d8;
}
ul.p-list-group li:last-child{
    border:0;
}

ul.p-list-search-custom{
    width: 400px!important;
    overflow-x: hidden;
    overflow-y: auto;
}
ul.p-list-search-custom:before{
    right: 3px;
    left: inherit!important;
}

@media (max-width: 1024px){
    ul.p-list-search-custom {
        width: 100%!important;
        overflow: auto;
    }
}


.p-fs-italic{
    font-style: italic;
}

.p-inputgroup .p-dropdown{
    min-width: auto!important;
}

.p-inputgroup .p-dropdown-label, .p-inputgroup .p-dropdown-trigger, .p-inputgroup .p-multiselect-label-container{
    margin-top: 8px!important;
}

.p-inputgroup .p-dropdown-trigger, .p-inputgroup .p-multiselect-trigger{
    margin-right: 10px!important;
}

.p-inputgroup .p-checkbox, .p-inputgroup .p-radiobutton{
    margin: 0 0.5em 0 0;
}

.fa-stack{
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
}

.fa-stack[data-count]:after{
    position:absolute;
    right:-20px;
    top:-10px;
    content: attr(data-count);
    font-size:80%;
    font-weight: bold;
    padding:.4em;
    border-radius:999px;
    line-height:.75em;
    color: #2e6da4;
    text-align:center;
    min-width:1em;
    font-weight:bold;
    background: white;
    border-style:solid;
}

.p-inputgroup .p-dropdown-async{
    background-image: none;
}

/*---logo---*/
.layout-wrapper:not(.layout-wrapper-static) .layout-sidebar:not(.layout-sidebar-active) .sidebar-logo a > img.app-logo{
    right: 5px;
    position: absolute;
}

.sidebar-logo a > span.app-name .p-dropdown{
    background-image: none;
}

.sidebar-logo a > span.app-name .p-dropdown .p-dropdown-trigger{
    height: 100%;
}

.qr-box{
    width: 95%;
}

.p-vertical-c {
    align-items: center;
}

.order-item-view h3.cate-title{
    font-size: 16px;
    margin: 10px 0px 5px 0px;
}

.order-item-view h3.cate-title:first-child{
    margin-top: 5px;
}

.background-image-none{
    background-image: none!important;
}

.p-rating-custom .pi-star, .p-rating-custom .pi-md-star{
    color: #d6cb49!important;
}

.order-list-item{
    border: solid #e9ecef;
    border-width: 1px 0 0 0;
    padding: 9px 0;
}

.p-display-block {
    display: block;
}

.p-fieldset.p-fieldset-padding-sm{
    padding-left: 7.5px; 
    padding-right: 7.5px;
}

.p-fieldset-sm .p-fieldset-legend {
    padding:0!important;
}

.p-fieldset-no-legend .p-fieldset-legend {
    padding: 0!important;
    padding-inline-start: 0!important;
    padding-inline-end: 0!important;    
}

.p-display-inline {
    display: inline;
}

.p-display-inline-block {
    display: inline-block;
}

.p-border-gray{
    border: 1px solid #d8d8d8;
}

.p-inputtext.p-frames{
    border: 1px solid #ced4da;
    border-radius: 2px;
    background: none;
    padding:2px 5px;
    line-height: 28px;
}

.p-spinner.p-frames .p-inputtext{
    border: 1px solid #ced4da;
    border-radius: 2px;
    background: none;
    line-height: 28px;
    padding: 2px 1.5em 2px 5px;
}

.p-spinner.p-frames .p-spinner-button-up{
    top: 5px;
}
.p-spinner.p-frames .p-spinner-button-down{
    bottom: 5px;
}

.p-dropdown.p-frames {
    border: 1px solid #ced4da;
    border-radius: 2px;
    background: none;
    padding:2px 5px;
}

.p-dropdown.p-frames .p-dropdown-label {
    line-height: 26px!important;
    margin-bottom: 0px!important;
}

.p-dropdown.p-frames .p-dropdown-trigger .p-dropdown-trigger-icon{
    margin-top:-4px;
}

.p-calendar.p-frames{
    border: 1px solid #ced4da;
    border-radius: 2px;
    padding: 2px 5px;
}

.p-calendar.p-frames .p-inputtext{
    padding: 0;
    background: none;
    line-height: 28px;
}

.p-calendar.p-frames .p-calendar-button{
    top: 5px!important;
}

.p-select.p-frames div[class$="indicatorContainer"]{
    padding: 6px;
}

.p-select.p-frames div[class$="group"]{
    color: #000;
    padding-bottom: 5px;
    text-transform: none;
    font-size: 15px;
    font-weight: bold;
}

.p-select.p-frames div[class$="Group"] div[class$="option"]{
    padding-left: 25px;
}

.p-inputtext.p-frames:hover,
.p-spinner.p-frames .p-inputtext:hover,
.p-dropdown.p-frames:hover,
.p-calendar.p-frames:hover{
    border: 1px solid #5aaf4b;
}

.p-inputtext.p-frames:focus,
.p-inputtext.p-frames.p-focus,
.p-spinner.p-frames .p-inputtext:focus,
.p-spinner.p-frames .p-inputtext.p-focus,
.p-dropdown.p-frames:focus,
.p-dropdown.p-frames.p-focus,
.p-calendar.p-frames:focus-within{
    border: 1px solid #5aaf4b!important;
    padding-bottom: 0px;
    /* outline: 0; */
    box-shadow: 0 0 0 3px rgba(0,116,217,0.2);
    /* boxShadow: '0 0 0 1px #2684ff' */
}

.react-dropdown-select.p-frames{
    min-height: 34px;
}

.p-promo-note{
    opacity: 0.7;
    font-size: 12px;
}

.p-menu-discount-bar{
    margin: 5px 5px 0px 5px;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}

.p-product-discount-bar{
    margin: 10px 0px 0px 0px;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
}